import React, { FC, forwardRef } from 'react';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { SxProps } from '@mui/material';
import TwSplitButton from './SplitButton/SplitButton';
import TwIconOnlyButton from './IconOnlyButton/IconOnlyButton';
import './TwButtons.scss';

export enum TwButtonType {
  primary = 'primary',
  secondary = 'secondary',
  positive = 'positive',
  negative = 'negative',
  clearAll = 'clearAll',
  text = 'text'
}

export interface TwButtonProps {
  /**
   * Label text for the button
   */
  label: string;
  /**
   * Classname for the button
   */
  className?: string;
  /**
   * Start icon as: <Add />
   */
  startIcon?: React.ReactNode;
  /**
   * End icon as: <Add />
   */
  endIcon?: React.ReactNode;
  /**
   * Button status
   */
  disabled?: boolean;
  /**
   * Button action
   */
  action: () => void;
  /**
   * Button type: primary or secondary.
   */
  type: TwButtonType;
  /**
   * Makes the button behave as submit when true
   */
  submit?: boolean;
  /**
   * Fullwidth: true or false
   */
  fullwidth?: boolean;
  /**
   * Sx props
   */
  sx?: SxProps;
}

const TwButton = forwardRef<HTMLButtonElement, TwButtonProps>(
  (
    {
      label,
      className,
      startIcon,
      endIcon,
      disabled,
      type,
      submit,
      fullwidth,
      sx,
      action,
      ...restProps
    },
    ref
  ) => {
    const buttonClasses =
      type === TwButtonType.primary
        ? 'tw-button__primary tid-primary-button'
        : type === TwButtonType.secondary
        ? 'tw-button__secondary tid-secondary-button'
        : type === TwButtonType.positive
        ? 'tw-button__positive tid-positive-button'
        : type === TwButtonType.negative
        ? 'tw-button__negative tid-negative-button'
        : type === TwButtonType.clearAll
        ? 'tw-button__clearall tid-clearAll-button'
        : type === TwButtonType.text
        ? 'tw-button__text tid-text-button'
        : '';

    return (
      <Button
        ref={ref}
        className={`${buttonClasses} ${className}`}
        startIcon={startIcon}
        endIcon={endIcon}
        disabled={disabled}
        variant={type === TwButtonType.text ? 'text' : 'contained'}
        fullWidth={fullwidth}
        onClick={(): void => action()}
        sx={sx}
        type={submit ? 'submit' : undefined}
        disableRipple={type === TwButtonType.text}
        {...restProps}
      >
        {label}
      </Button>
    );
  }
);

TwButton.defaultProps = {
  fullwidth: false,
  disabled: false
};

TwButton.displayName = 'TwButton';

interface TwLoadingButtonProps extends TwButtonProps {
  loading: boolean;
}

const TwLoadingButton: FC<TwLoadingButtonProps> = ({
  loading,
  label,
  className,
  startIcon,
  disabled,
  type,
  fullwidth,
  action
}) => {
  const buttonClasses =
    type === TwButtonType.primary
      ? 'tw-button__primary tid-primary-button'
      : type === TwButtonType.secondary
      ? 'tw-button__secondary tid-secondary-button'
      : type === TwButtonType.positive
      ? 'tw-button__positive tid-positive-button'
      : type === TwButtonType.negative
      ? 'tw-button__negative tid-negative-button'
      : type === TwButtonType.clearAll
      ? 'tw-button__clearall tid-clearAll-button'
      : type === TwButtonType.text
      ? 'tw-button__text tid-text-button'
      : '';

  return (
    <LoadingButton
      loading={loading}
      className={`${buttonClasses} ${className}`}
      startIcon={startIcon}
      disabled={disabled}
      variant="contained"
      fullWidth={fullwidth}
      onClick={(): void => action()}
    >
      {label}
    </LoadingButton>
  );
};

TwLoadingButton.defaultProps = {
  fullwidth: true,
  disabled: false,
  loading: false
};

/**
 * Submit Button
 *
 * The same as a regular button without an onClick/action function
 * This helps to avoid a double submit in Formik Forms
 */
const TwSubmitButton = forwardRef<HTMLButtonElement, Omit<TwButtonProps, 'action' | 'submit'>>(
  ({ label, className, startIcon, endIcon, disabled, type, fullwidth, sx, ...restProps }, ref) => {
    const buttonClasses =
      type === TwButtonType.primary
        ? 'tw-button__primary tid-primary-button'
        : type === TwButtonType.secondary
        ? 'tw-button__secondary tid-secondary-button'
        : type === TwButtonType.positive
        ? 'tw-button__positive tid-positive-button'
        : type === TwButtonType.negative
        ? 'tw-button__negative tid-negative-button'
        : '';

    return (
      <Button
        ref={ref}
        className={`${buttonClasses} ${className}`}
        startIcon={startIcon}
        endIcon={endIcon}
        disabled={disabled}
        variant="contained"
        fullWidth={fullwidth}
        sx={sx}
        type={'submit'}
        {...restProps}
      >
        {label}
      </Button>
    );
  }
);

TwSubmitButton.displayName = 'TwSubmitButton';

export { TwButton, TwSubmitButton, TwLoadingButton, TwSplitButton, TwIconOnlyButton };
