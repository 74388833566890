import React, { FC } from 'react';
import IconButton from '@mui/material/Button';

export interface IconOnlyButtonProps {
  /**
   * Aria label for the button
   */
  ariaLabel: string;
  /**
   * Classname for the button
   */
  className?: string;
  /**
   * Icon as: <Add />
   */
  icon: React.ReactNode;
  /**
   * Button status
   */
  disabled?: boolean;
  /**
   * Button action
   */
  action: () => void;

  defaultClass?: boolean;
}

const IconOnlyButton: FC<IconOnlyButtonProps> = ({
  ariaLabel,
  icon,
  disabled,
  action,
  className,
  defaultClass = true
}) => {
  return (
    <IconButton
      aria-label={ariaLabel}
      disabled={disabled}
      onClick={action}
      className={`${className} tid-icon-button`}
      sx={
        defaultClass
          ? {
              borderRadius: '50%',
              minWidth: '20px',
              border: disabled ? '2px solid rgba(0, 0, 0, 0.26);' : '2px solid #7E858E',
              color: '#7E858E',
              padding: '2px'
            }
          : {}
      }
    >
      {icon}
    </IconButton>
  );
};

IconOnlyButton.defaultProps = {
  disabled: false,
  className: ''
};

export default IconOnlyButton;
