import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { TwSnackbarOptions } from '../../components/TwSnackbar/TwSnackbar';
import TwSnackbar from '../../components/TwSnackbar/TwSnackbar';

export interface NotificationsProviderData {
  notifications: TwSnackbarOptions[];
  isShowing: boolean;
}

export interface NotificationsContextProps {
  data: NotificationsProviderData;
  addNotification: (notification: TwSnackbarOptions) => void;
  removeNotification: () => TwSnackbarOptions | null;
  setIsShowing: (value: boolean) => void;
}

export const defaultProviderData = {
  notifications: [] as TwSnackbarOptions[],
  isShowing: false
};
export const NotificationsContext = createContext<NotificationsContextProps>({
  data: defaultProviderData,
  addNotification: () => null,
  removeNotification: () => null,
  setIsShowing: () => null
});

export const NotificationsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [providerValue, setProviderValue] = useState(defaultProviderData);
  const [currentNotification, setCurrentNotification] = useState<TwSnackbarOptions>();

  useEffect(() => {
    if (providerValue.notifications.length > 0) {
      if (!providerValue.isShowing) {
        setIsShowing(true);
      }
      const lastNotification = providerValue.notifications[providerValue.notifications.length - 1];
      setCurrentNotification(lastNotification);
    }
  }, [providerValue.notifications]);

  useEffect(() => {
    if (!providerValue.isShowing) {
      setCurrentNotification(undefined);
      removeNotification();
    }
  }, [providerValue.isShowing]);

  /**
   * @description add new element to notifications array
   * @param {TwSnackbarOptions} notification
   */
  const addNotification = (notification: TwSnackbarOptions): void => {
    setProviderValue((previousValues) => {
      return { ...previousValues, notifications: [...previousValues.notifications, notification] };
    });
  };

  /**
   * @description get first notification element
   * @returns {TwSnackbarOptions|null} notifications
   */
  const removeNotification = (): TwSnackbarOptions | null => {
    if (providerValue.notifications.length > 0) {
      const value = providerValue.notifications[0];
      setProviderValue((previousValues) => {
        return {
          ...previousValues,
          notifications: previousValues.notifications.filter((notification, index) => index > 0)
        };
      });
      return value;
    } else {
      return null;
    }
  };

  /**
   * @description set isShowing value
   * @param {boolean} value
   */
  const setIsShowing = (value: boolean): void => {
    setProviderValue((previousValues) => {
      // if false then remove last notification
      const notifications = value
        ? previousValues.notifications
        : previousValues.notifications.slice(0, -1);

      return { ...previousValues, isShowing: value, notifications: notifications };
    });
  };

  const providerData = {
    data: providerValue,
    addNotification,
    removeNotification,
    setIsShowing
  };

  return (
    <NotificationsContext.Provider value={providerData}>
      {currentNotification && (
        <TwSnackbar options={currentNotification} open={providerValue.isShowing} />
      )}
      {children}
    </NotificationsContext.Provider>
  );
};

export default function useNotifications(): NotificationsContextProps {
  return useContext(NotificationsContext);
}
