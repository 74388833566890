import { useEffect } from 'react';

export interface ScriptConfig {
  resourceUrl: string;
  element: string;
  scriptText?: string;
  async?: boolean;
  defer?: boolean;
  id?: string;
  onLoad?: () => void;
}
/**
 * Creates a script within HTML
 * @param config
 */
const useLoadScript = (config: ScriptConfig): void => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = config.resourceUrl;
    config.scriptText ? (script.innerHTML = config.scriptText) : null;
    config.async ? (script.async = config.async) : null;
    config.defer ? (script.defer = config.defer) : null;
    config.id ? (script.id = config.id) : null;

    if (config.element === 'body') {
      document.body.appendChild(script);
    } else {
      document.head.appendChild(script);
    }

    if (!!config.onLoad) {
      script.onload = (): void => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        config.onLoad!();
      };
    }

    return (): void => {
      if (config.element === 'body') {
        document.body.removeChild(script);
      } else {
        document.head.removeChild(script);
      }
    };
  }, []);
};

export default useLoadScript;
