import React, { useEffect } from 'react';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

export type ServiceWorkerHookResult = {
  showReload: boolean;
  serviceWorker: null | ServiceWorker;
  reloadPage: () => void;
};

export default function useServiceWorker(): ServiceWorkerHookResult {
  const [serviceWorker, setServiceWorker] = React.useState<ServiceWorker | null>(null);
  const [showReload, setShowReload] = React.useState(false);

  const onServiceWorkerUpdate = React.useCallback((registration: ServiceWorkerRegistration) => {
    setServiceWorker(registration.waiting);
    if (!isSafariOnIOSMobile()) {
      setShowReload(true);
    } else {
      // for now we're just hiding if we're on ios safari
      setShowReload(false);
    }
  }, []);

  // function to see if we're running on ios safari
  function isSafariOnIOSMobile(): boolean {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    // Check if on an iOS platform
    const isIOS =
      iosPlatforms.includes(platform) ||
      /iPad|iPhone|iPod/.test(userAgent) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

    // Safari on iOS mobile condition: isIOS && not in standalone mode
    return isIOS && !(window.navigator as any).standalone;
  }

  const reloadPage = React.useCallback(() => {
    serviceWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload();
  }, [serviceWorker]);

  useEffect(() => {
    // skipping all of this on ios safari
    if (isSafariOnIOSMobile()) return;

    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then((registration) => {
        if (registration.waiting) {
          setServiceWorker(registration.waiting);
          setShowReload(true);
        }
      });
    }

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({
      onUpdate: onServiceWorkerUpdate
    });
  }, [onServiceWorkerUpdate]);

  return { showReload, serviceWorker, reloadPage };
}
