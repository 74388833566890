import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box } from '@mui/material'; // Import Material-UI components
import CloseIcon from '@mui/icons-material/Close'; // Import CloseIcon
import { ReactComponent as TbLogo } from '../../assets/truckbase-dark-logo.svg';
import { TwButton, TwButtonType } from '../TwButtons/TwButtons';
import useFeatureFlags from '../../providers/FeatureFlags/FeatureFlags.provider';

const TwBanner: React.FC = () => {
  const { enableDownloadAppBanner } = useFeatureFlags();
  const [isOpen, setIsOpen] = useState(true); // State to manage banner visibility

  const isAndroid = /Android/i.test(navigator.userAgent);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  const isWebInFlutter = navigator.userAgent.includes('Flutter');

  const handleClick = (): void => {
    let linkUrl = '';
    if (isAndroid) {
      linkUrl = 'https://play.google.com/store/apps/details?id=com.truckbase.app';
    } else {
      linkUrl = 'https://apps.apple.com/us/app/truckbase/id6477065686';
    }
    window.open(linkUrl, '_blank');
  };

  const handleCloseClick = (): void => {
    setIsOpen(false); // Close the banner
  };

  if (!enableDownloadAppBanner) {
    return null;
  }

  if (!isOpen || (!isAndroid && !isIOS) || isWebInFlutter) {
    // Return null if the banner is closed or the user's OS is neither Android nor iOS
    return null;
  }

  return (
    <AppBar position="sticky" sx={{ bgcolor: '#1d212c' }}>
      {' '}
      {/* Sticky app bar with custom background color */}
      <Toolbar sx={{ paddingLeft: '8px', paddingRight: '8px' }}>
        <Box sx={{ width: '24px' }}>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="close"
            sx={{ width: '24px', height: '24px' }}
            onClick={handleCloseClick}
          >
            {' '}
            {/* Close icon button */}
            <CloseIcon sx={{ width: '16px', height: '16px' }} />
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <TbLogo style={{ width: '32px', height: '32px' }} />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              flexGrow: 1,
              marginLeft: '10px'
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ color: 'white', fontWeight: 'bold', textAlign: 'left', lineHeight: '1' }}
            >
              {' '}
              {/* Black title */}
              Truckbase
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: '#b5b5be', textAlign: 'left', fontSize: '0.8rem' }}
            >
              {' '}
              {/* Subtitle */}
              Truckbase is better in the app
            </Typography>
          </Box>
        </Box>
        <Box>
          <TwButton
            label="Use App"
            disabled={false}
            type={TwButtonType.primary}
            fullwidth={false}
            action={handleClick}
            sx={{ height: '32px' }}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TwBanner;
