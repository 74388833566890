import { EquipmentFile } from '../equipmentFile/equipmentFile.model';
import { EquipmentProfile } from '../equipmentProfile/equipmentProfile.model';
import { TRUCKER_CLOUD_ASSET_ELD_ID_KEY } from '../eld/eld.model';

/**
 * Possible equipment types.
 */
export enum EquipmentType {
  truck = 'TRUCK',
  trailer = 'TRAILER'
}

export enum TRUCKERCLOUD_TRIP_STATUS_TYPE {
  QUEUED = 'QUEUED',
  STARTED = 'STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  LATE = 'LATE'
}

export interface LatestEquipmentReadingInfo {
  truckercloud_trip_status: TRUCKERCLOUD_TRIP_STATUS_TYPE;
  latitude: string;
  longitude: string;
  odometer: number;
  truckercloud_reading_timestamp: string;
  truckercloud_reading_fetched_timestamp: string;
}

export interface EldConnectionInfo {
  id: string;
  name: string;
}

export enum ELDConnectionStatus {
  CONNECTED = 'CONNECTED',
  CACHED = 'CACHED',
  NO_ELD_DATA = 'NO_ELD_DATA'
}

/**
 * Load equipment (vehicles) info.
 */
export interface Equipment {
  id: string;
  type: EquipmentType;
  name: string;
  tenant_id?: string;
  loads_completed?: string;
  created_by?: string;
  updated_by?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  asset_eld_id?: string;
  asset_eld_id_key?: TRUCKER_CLOUD_ASSET_ELD_ID_KEY;
  eld_connection_id?: string;
  latest_reading?: LatestEquipmentReadingInfo;
  eld_connection?: EldConnectionInfo;
  equipment_profile: EquipmentProfile | null;
  equipment_files: EquipmentFile[] | null;
}

/**
 * Equipment creation data for request
 */
export interface CreateEquipmentParams {
  name: string;
  type?: EquipmentType;
  eld_connection_id?: string;
  asset_eld_id?: string;
  asset_eld_id_key?: TRUCKER_CLOUD_ASSET_ELD_ID_KEY;
}

/**
 * Equipment data to edit tracking
 */
export type EquipmentTracking = Pick<
  Equipment,
  'eld_connection_id' | 'asset_eld_id' | 'asset_eld_id_key'
>;

/**
 * Equipment data required from user in creation
 */
export interface CreateEquipmentData {
  name: string;
  type?: EquipmentType;
}

export interface UpdateEquipmentParams extends CreateEquipmentParams {
  id: string;
}

export interface LastKnownLocationParams {
  truck_id: string;
  segment_id?: string;
  start_date: Date;
  start_time?: string;
}
