import React, { FC, useRef, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

export interface SplitButtonOption {
  text: string;
  onClick: () => void;
}

export type SplitButtonType = 'primary' | 'secondary';

interface SplitButtonProps {
  label: string;
  disabled?: boolean;
  options: SplitButtonOption[];
  variant?: SplitButtonType;
  /**
   * If true, the icon button will be displayed as a dropdown icon and will trigger dropdown menu
   */
  isDropdownIcon?: boolean;
  onIconButtonClick?: () => void;
  onMainButtonClick?: () => void;
  actionButtonIcon?: React.ReactNode;
  startIcon?: React.ReactNode;
}

const SplitButton: FC<SplitButtonProps> = ({
  label,
  disabled,
  options,
  variant,
  onIconButtonClick,
  onMainButtonClick,
  isDropdownIcon = false,
  actionButtonIcon = <ArrowForwardIosIcon sx={{ width: '12px' }} />,
  startIcon
}) => {
  const anchorRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState<boolean>(false);

  const mainButtonSx = {
    display: 'flex',
    justifyContent: 'flex-start',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    borderRightColor: variant === 'primary' ? '#fff' : '#1F2937',
    textTransform: 'none',
    backgroundColor: variant === 'primary' ? '#3A4DE9' : 'white',
    color: variant === 'primary' ? '#fff' : '#1F2937',
    marginRight: '1px',
    minWidth: '128px !important',
    '&:hover': {
      color: '#9090a6',
      backgroundColor: variant === 'primary' ? '#3959df' : 'white'
    }
  } as const;

  const smallButtonSx = {
    backgroundColor: variant === 'primary' ? '#3A4DE9' : 'white',
    color: variant === 'primary' ? '#fff' : '#1F2937',
    '&:hover': {
      color: '#9090a6',
      backgroundColor: variant === 'primary' ? '#3959df' : 'white'
    }
  };

  const handleClose = (e: Event): void => {
    if (anchorRef.current && anchorRef.current.contains(e.target as HTMLElement)) return;

    setOpen(false);
  };

  const toggleDropdown = (): void => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <ButtonGroup
        ref={anchorRef}
        variant="contained"
        disabled={disabled}
        aria-label="split-button"
        disableElevation
        sx={
          variant !== 'primary'
            ? {
                border: '1px solid #D1D5DB',

                '.MuiButtonGroup-grouped:not(:last-of-type)': {
                  borderColor: '#D1D5DB'
                }
              }
            : {}
        }
      >
        <Button
          onClick={(): void => {
            isDropdownIcon && onMainButtonClick ? onMainButtonClick() : toggleDropdown();
          }}
          sx={mainButtonSx}
          startIcon={startIcon}
        >
          {label}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={(): void => {
            isDropdownIcon ? toggleDropdown() : onIconButtonClick ? onIconButtonClick() : null;
          }}
          sx={smallButtonSx}
        >
          {actionButtonIcon}
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{
          zIndex: '1'
        }}
      >
        {/* eslint-disable-next-line @typescript-eslint/explicit-function-return-type */}
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
          >
            <Paper sx={{ minWidth: '168px' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, i) => (
                    <MenuItem
                      key={i}
                      onClick={(): void => {
                        option.onClick();
                        setOpen(false);
                      }}
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '14px'
                      }}
                    >
                      {option.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

SplitButton.defaultProps = {
  label: '',
  disabled: false,
  variant: 'primary',
  onIconButtonClick: (): null => null
};

export default SplitButton;
