import { USER_ROLE, UserResponse } from '../providers/User/User.model';

function startIntercom(user: UserResponse | undefined): void {
  let userData = {};

  if (user) {
    userData = {
      user_id: user.id,
      name: `${user.first_name} ${user.last_name}`,
      email: user.email_address,
      company: {
        id: user.tenant?.id,
        name: user.tenant?.company_name
      },
      user_hash: user.intercom_user_hash
    };
  }

  window.Intercom('boot', { app_id: 'vdunphop', ...userData });
}

export function hideWidget(): void {
  window.Intercom('shutdown');
}

export const widgetAllowedPaths = ['/orders', '/trips', '/dashboard'];

export function showWidget(enableIntercomFlag: boolean, user: UserResponse | undefined): void {
  const path = window.location.pathname;
  // see if current path is allowed if it contains any of the allowed paths
  const isVisible = widgetAllowedPaths.some(
    (allowedPath) => path.includes(allowedPath) || path === '/'
  );

  if (!isVisible) return;

  const userRole = user?.role;

  if (!userRole || userRole === USER_ROLE.CUSTOMER_CONTACT || userRole === USER_ROLE.DRIVER) return;
  if (enableIntercomFlag) {
    startIntercom(user);
  } else return;
}
