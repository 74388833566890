/**
 * @description a simple implementation for generate uique ids, if this project is gonna use react >=18
 * destroy this hook and use react implementation
 * @returns string
 */

const useId = (): string => {
  return (1e7 + -1e3 + -4e3 + -8e3 + -1e11)
    .toString()
    .replace(/[018]/g, (c: any) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
};

export default useId;
