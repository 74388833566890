import palette from './palette';
import typography from './typography';

const themeOptions = {
  palette: palette,
  spacing: 8,
  typography: typography
};

export default themeOptions;
