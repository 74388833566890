import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface InboxState {
  inboxItemCount?: number;
  ediCount?: number;
  emailCount?: number;
  customerOrderCount?: number;
}

export const inboxSlice = createSlice({
  name: 'inbox',
  initialState: {
    inboxItemCount: 0
  } as InboxState,
  reducers: {
    updateItemCount: (state, action: PayloadAction<number | undefined>) => {
      state.inboxItemCount = action.payload;
    },
    updateCountBySection: (
      state,
      action: PayloadAction<
        | {
            ediCount?: number;
            emailCount?: number;
            customerOrderCount?: number;
          }
        | undefined
      >
    ) => {
      state.ediCount = action.payload?.ediCount;
      state.emailCount = action.payload?.emailCount;
      state.customerOrderCount = action.payload?.customerOrderCount;
    }
  }
});

export const { updateItemCount, updateCountBySection } = inboxSlice.actions;

export default inboxSlice.reducer;
