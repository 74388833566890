import { SurveyOption } from './Auth.model';

/** Set of options for Welcome page survey.
 * User possible Roles, Reasons to use Truckbase,
 * Owned trucks options, Loads per week, Freight types.
 */
export interface SurveyOptions {
  roleList: SurveyOption[];
  reasonList: SurveyOption[];
  ownedTrucksList: SurveyOption[];
  loadsPerWeekList: SurveyOption[];
  freightOptions: SurveyOption[];
}

const surveyOptionsList: SurveyOptions = {
  roleList: [
    {
      value: 'Owner',
      label: 'Owner'
    },
    {
      value: 'Dispatcher',
      label: 'Dispatcher'
    },
    {
      value: 'Driver',
      label: 'Driver'
    },
    {
      value: 'Accountant or Bookkeeper',
      label: 'Accountant or Bookkeeper'
    },
    {
      value: 'Other',
      label: 'Other'
    }
  ],
  reasonList: [
    {
      value: 'Save time',
      label: 'Save time'
    },
    {
      value: 'Stay organized',
      label: 'Stay organized'
    },
    {
      value: 'Make sure I get paid',
      label: 'Make sure I get paid'
    },
    {
      value: 'Other',
      label: 'Other'
    }
  ],
  ownedTrucksList: [
    {
      value: '0',
      label: '0'
    },
    {
      value: '1',
      label: '1'
    },
    {
      value: '2-5',
      label: '2-5'
    },
    {
      value: '6+',
      label: '6+'
    }
  ],
  loadsPerWeekList: [
    {
      value: '1',
      label: '1'
    },
    {
      value: '2-5',
      label: '2-5'
    },
    {
      value: '6-10',
      label: '6-10'
    },
    {
      value: '11+',
      label: '11+'
    }
  ],
  freightOptions: [
    {
      label: 'Dry Van',
      value: 'Dry Van'
    },
    {
      label: 'Refrigerated',
      value: 'Refrigerated'
    },
    {
      label: 'Flatbed',
      value: 'Flatbed'
    },
    {
      label: 'LTL',
      value: 'LTL'
    },
    {
      label: 'Hotshot',
      value: 'Hotshot'
    },
    {
      label: 'Oversized',
      value: 'Oversized'
    },
    {
      label: 'Tanker',
      value: 'Tanker'
    },
    {
      label: 'Intermodal',
      value: 'Intermodal'
    },
    {
      label: 'Auto',
      value: 'Auto'
    },
    {
      label: 'Other',
      value: 'Other'
    }
  ]
};

export default surveyOptionsList;
