import {
  InvoiceItem,
  InvoiceSources,
  INVOICE_STATUS
} from '../../../providers/Invoices/Invoices.model';
import { LoadNotificationSettingsRequest } from '../../../providers/Settings/LoadNotificationSettings.model';

import { USER_ROLE } from '../../../providers/User/User.model';
import { BaseQueryParams } from '../../Global.model';
import { ClientResponse, IContactClient } from '../customer/customer.model';

import { DRIVER_DEFAULT_PAY_RATE_TYPE, Driver } from '../driver/driver.model';

import { InvoiceServiceUnit } from '../invoiceService/invoiceService.model';

import { ORDER_STATUS } from '../orders/orders.model';
import { StopStatus, TripData, TripDriver } from '../trips/trips.model';
import { EdiDelayReasonCode } from '../../../components/TwEdiMessageUpdate/TwEdiDelayReasonList';

/**
 * Possible statuses for a Load
 */
export enum LoadStatus {
  canceled = 'CANCELED',
  draft = 'DRAFT',
  dispatched = 'DISPATCHED',
  inTransit = 'IN_TRANSIT',
  delivered = 'DELIVERED',
  readyForInvoicing = 'READY_FOR_INVOICING',
  invoiced = 'INVOICED',
  paid = 'PAID'
}

/*
 * Load status colors
 */
export enum LoadStatusColor {
  CANCELED = '#EF4444',
  DRAFT = '#9CA3AF',
  DISPATCHED = '#A855F7',
  IN_TRANSIT = '#3A4DE9',
  DELIVERED = '#10B981',
  READY_FOR_INVOICING = '#10B981',
  INVOICED = '#10B981',
  PAID = '#10B981'
}

/**
 * Trip status colors
 */

export enum TripStatusColor {
  NOT_STARTED = '#6B7280',
  DISPATCHED = '#A855F7',
  IN_TRANSIT = '#3A4DE9',
  DELIVERED = '#10B981'
}

/**
 * Possible statuses for a Segment in Split loads
 */
export enum SegmentStatus {
  notStarted = 'NOT_STARTED',
  dispatched = 'DISPATCHED',
  inTransit = 'IN_TRANSIT',
  delivered = 'DELIVERED'
}

/**
 * Possible client types for primary client: broker or shipper.
 */
export enum LoadClientType {
  broker = 'BROKER',
  shipper = 'SHIPPER'
}

/**
 * Possible load types
 */
export enum LOAD_TYPE {
  SIMPLE = 'SIMPLE',
  SEGMENT = 'SEGMENT'
}

/**
 * Time zone usage values to display pickup/delivery dates according to load tz setting.
 */
export enum TZ_USAGE {
  USER = 'USER',
  LOCAL = 'LOCAL'
}

/**
 * Intermediate stop type.
 */
export enum STOP_TYPE {
  PICKUP = 'PICKUP',
  DROP_OFF = 'DROP_OFF',
  TRANSFER = 'TRANSFER'
}

/**
 * Load assigned status
 */
export enum ASSIGNED_STATUS {
  ASSIGNED_TO_DRIVER = 'ASSIGNED_TO_DRIVER',
  NOT_ASSIGNED_TO_DRIVER = 'NOT_ASSIGNED_TO_DRIVER',
  ASSIGNED_TO_CARRIER = 'ASSIGNED_TO_CARRIER',
  NOT_ASSIGNED_TO_CARRIER = 'NOT_ASSIGNED_TO_CARRIER'
}

export interface LoadCommodities {
  id?: string;
  ui_uid: string;
  description: string | null;
  quantity: number | null;
  weight: number | null;
}

/**
 * Load client information (brokers and shippers).
 */
export interface LoadClient {
  id: string;
  name: string;
  logo_url?: string;
  billing_email?: string;
  contact?: IContactClient;
  initials?: string;
  primary_phone?: string;
  location_id?: string;
  location?: LoadLocation;
  contacts?: IContactClient[];
  poc_first_name?: string;
  poc_last_name?: string;
  poc_email_address?: string;
  poc_phone?: number;
  tenant_id?: string;
}

/**
 * Load location info.
 */
export interface LoadLocation {
  id: string;
  name: string;
  shipper_id?: string;
  address: string;
  address_1: string;
  address_2?: string;
  city: string;
  country: string;
  state: string;
  zip?: string;
  longitude?: number;
  latitude?: number;
  tenant_id?: string;
  is_zip_approximated?: boolean;
  timezone?: string;
  short_offset?: string;
  full_address: string;
  is_appointment_required?: boolean;
  notes?: string;
  contact_name?: string;
  contact_number?: string;
  contact_number_ext?: string;
  contact_email?: string;
  isUsedByOrders?: number;
  customers?: ClientResponse[];
  [key: string]: any;
}

export interface LocationMilageResponse {
  milage: string;
}

/**
 * Load document types.
 */
export enum LoadDocumentType {
  proofOfDelivery = 'PROOF_OF_DELIVERY',
  rateConfirmation = 'RATE_CONFIRMATION',
  billOfLading = 'BILL_OF_LADING',
  lumper = 'LUMPER',
  receipt = 'RECEIPT',
  invoice = 'INVOICE',
  other = 'OTHER',
  empty = 'EMPTY'
}

/**
 * Load document info.
 */
export interface LoadDocument {
  id: string;
  name: string;
  size: number;
  type: LoadDocumentType;
  key: string;
  file_type: string;
  load_id: string;
  tenant_id?: string;
  created_by?: string;
  updated_by?: string;
  created_at?: string;
  updated_at?: string;
  driver?: Driver;
  uploaded_by?: string;
  visibility?: boolean;
  is_encrypted?: boolean;
  is_generated: boolean;
}

/**
 * Upload document request info.
 */
export interface LoadDocumentParams {
  document: File;
  type: LoadDocumentType;
  visibility?: boolean;
}

/**
 * Load photo info.
 */
export interface LoadPhoto {
  id: string;
  key: string;
  size: number;
  file_type: string;
  load_id: string;
  tenant_id?: string;
}

/**
 * Load photo interface for detail usage.
 */
export interface DetailLoadPhoto extends LoadPhoto {
  src?: string;
  driver?: Driver;
}

/**
 * Save load as PDF request info
 */
export interface LoadSavePDFParams {
  documents?: string[];
  photos?: string[];
  tenantCC?: boolean;
  include_invoice: boolean;
  sendTo?: string[];
  email_message?: string;
  subject?: string;
  merge_attachments?: boolean;
}

/**
 * Possible share type for a Load
 */
export enum ShareType {
  load_update = 'ORDER_UPDATE',
  load_driver_link = 'ORDER_DRIVER_LINK',
  invoice_history = 'SEND_INVOICE'
}

/**
 * Possible share channel for a Load
 */
export enum ShareChannel {
  email = 'EMAIL',
  sms = 'SMS'
}

export interface LoadRateCalculationResponse {
  effective_rate: number;
  rate_per_mile: number;
}

export interface LoadSharedTokenResponse {
  id: string;
  token: string;
  load_id: string;
}

/**
 * Create Load field names
 */
export type CreateLoadFields =
  | 'rate'
  | 'payable_amount'
  | 'start_date'
  | 'end_date'
  | 'customer'
  | 'shipper_or_broker';

/**
 * Create Load fields expected and required by status.
 */
export interface CreateLoadConfig {
  status: ORDER_STATUS;
  required_fields: CreateLoadFields[] | [];
  expected_fields: CreateLoadFields[] | [];
  expected_documents: LoadDocumentType[];
}
/**
 * Driver fields expected at load creation
 */
/* export interface CreateLoadDriver {
  id: string;
  send_driver_link?: boolean;
  driver_pay_override?: number;
  driver_pay_override_data?: GrossPayData;
  load_driver_pay_other_payment?: ISettlementDriverLoadAdjustmentType[];
  default_load_driver_other_pay?: ISettlementDriverLoadAdjustmentType[];
  has_gross_pay_adapted?: boolean;
  is_primary?: boolean;
} */

/**
 * Load creation or full edit data for request
 */ /* 
export interface UpsertLoadParams {
status: LoadStatus;
primary_client: LoadClientType;
tz_usage: TZ_USAGE;
shipper_id?: string;
broker_id?: string;
customer_contact_id?: string;
carrier_id?: string;
pickup_location_id?: string;
delivery_location_id?: string;
pickup_date: string;
pickup_from_time?: string;
pickup_to_time?: string;
delivery_date: string;
delivery_from_time?: string;
delivery_to_time?: string;
rate: number;
empty_miles?: number;
estimated_mileage?: number;
equipment_id?: string;
trailer_id?: string;
truck_id?: string;
instructions?: string;
customer_order_number?: string;
pickup_number?: string;
send_order_update?: boolean;
intermediate_stops?: Partial<LoadIntermediateStop>[];
dispatcher_id?: string;
drivers?: CreateLoadDriver[];
documents?: string[];
attachmentList?: LoadDocument[];
} */

/**
 * Import load params for creation
 */
/* export interface ImportLoadParams extends UpsertLoadParams {
  email_id?: string;
  edi_load_tender_id?: string;
  attachments?: ImportedLoadAttachmentParams[];
} */

/**
 * Import load data
 */
/* export interface ImportLoadData extends UpsertLoadData {
  emailId?: string;
  ediOrderId?: string;
  attachments?: ImportedLoadAttachmentData[];
} */

/**
 * Load data required from user in upsert request (i.e., creation or to do full update of existing load)
 */
/* export interface UpsertLoadData {
  status: LoadStatus;
  primaryClient: LoadClientType;
  shipperId?: string;
  brokerId?: string;
  contactId?: string;
  carrierId?: string;
  pickupLocationId?: string;
  deliveryLocationId?: string;
  pickupDate: string;
  pickupFromTime?: string;
  pickupToTime?: string;
  deliveryDate: string;
  deliveryFromTime?: string;
  deliveryToTime?: string;
  rate: number;
  emptyMiles?: number;
  estimatedMileage?: number;
  equipmentId?: string;
  trailerId?: string;
  truckId?: string;
  specialInstructions?: string;
  shipperLoadNumber?: string;
  pickupNumber?: string;
  timezoneUsage: TZ_USAGE;
  sendLoadUpdate?: boolean;
  intermediateStops?: Partial<LoadIntermediateStop>[];
  dispatcherId?: string;
  drivers?: CreateLoadDriver[];
  documents?: string[];
  attachmentList?: LoadDocument[];
} */

/**
 * Split load segment patch data
 */
/* export interface PatchUpdateSegment extends Partial<CreateSplitLoadSegmentParams> {
  status?: TripStatus;
} */

/**
 * Split load segment data required to create split load
 */ /* 
export interface CreateSplitLoadSegmentData {
startLocation?: string;
startDate: string;
startTimeFrom?: string;
startTimeTo?: string;
endLocation?: string;
endDate: string;
endTimeFrom?: string;
endTimeTo?: string;
selectedDriver?: string;
selectedTrailer?: string;
selectedTruck?: string;
selectedCarrier?: string;
sendDriverLink?: boolean;
grossPayEstimate?: number;
grossPayEstimateData?: LoadGrossPayEstimateData;
loadDriverOtherPay?: OtherLoadPayData[];
deadheadMiles?: number;
pickupNumber?: string;
specialInstructions?: string;
emptyMiles?: number | null;
estimatedMileage?: number | null;
startStopType?: STOP_TYPE | null;
endStopType?: STOP_TYPE | null;
id?: string;
} */

/**
 * Split Load data required from user in creation
 */
/* export interface CreateSplitLoadData {
  status: LoadStatus;
  rate: number;
  primaryClient: LoadClientType;
  shipperId?: string;
  brokerId?: string;
  contactId?: string;
  emptyMiles?: number;
  estimatedMileage?: number;
  shipperLoadNumber?: string;
  timezoneUsage: TZ_USAGE;
  sendLoadUpdate?: boolean;
  segments?: CreateSplitLoadSegmentData[];
  dispatcherId?: string;
  documents?: string[];
  attachmentList?: LoadDocument[];
  type?: LOAD_TYPE;
} */

/**
 * Split load segment data required to create split load
 */
/* export interface CreateSplitLoadSegmentParams {
  truck_id?: string | null;
  trailer_id?: string | null;
  start_location_id?: string | null;
  stop_location_id?: string | null;
  start_date: string;
  start_from_time?: string | null;
  start_to_time?: string | null;
  stop_date: string;
  stop_from_time?: string | null;
  stop_to_time?: string | null;
  drivers?: CreateLoadDriver[] | null;
  carrier_id?: string | null;
  instructions?: string | null;
  pickup_number?: string | null;
  empty_miles?: number | null;
  is_empty_miles_auto: boolean;
  estimated_mileage: number | null;
  order: number;
  stop_type?: STOP_TYPE | null;
  is_loaded_miles_auto: boolean;
} */
/**
 * Split Load params for create request
 */
/* export interface CreateSplitLoadParams {
  status: LoadStatus;
  rate: number;
  primary_client: LoadClientType;
  shipper_id?: string;
  broker_id?: string;
  customer_contact_id?: string;
  customer_order_number?: string;
  tz_usage: TZ_USAGE;
  segments?: CreateSplitLoadSegmentParams[];
  send_order_update?: boolean;
  dispatcher_id?: string;
  documents?: string[];
  attachmentList?: LoadDocument[];
} */

/**
 * Broker creation data for request
 */
export interface CreateBrokerParams {
  name: string;
  email_address?: string;
  phone?: string;
  poc_first_name?: string;
  poc_last_name?: string;
  poc_email_address?: string;
  poc_phone?: string;
}

/**
 * Broker data required from user in creation
 */
export interface CreateBrokerData {
  companyName: string;
  companyEmail?: string;
  companyPhone?: string;
  pocEmail?: string;
  pocFirstname?: string;
  pocLastname?: string;
  pocPhone?: string;
}

/**
 * Shipper creation data for request
 */
export interface CreateShipperParams {
  name: string;
  email_address?: string;
  phone?: string;
  poc_first_name?: string;
  poc_last_name?: string;
  poc_email_address?: string;
  poc_phone?: string;
}

/**
 * Shipper data required from user in creation
 */
export interface CreateShipperData {
  companyName: string;
  companyEmail?: string;
  companyPhone?: string;
  pocEmail?: string;
  pocFirstname?: string;
  pocLastname?: string;
  pocPhone?: string;
}

/**
 * Data required in order to calculate effective rate
 */
export interface CalculateEffectiveRateData {
  rate: number;
  estimated_mileage: number;
  empty_miles: number;
}

/**
 * Imported load (forwarded email) attachment
 */
export interface ImportedLoadAttachment {
  content_type: string;
  created_at: string;
  forwarded_email_id: string;
  id: string;
  key: string;
  name: string;
  size: number;
  updated_at: string;
}

/**
 * Import load attachment params
 */
export interface ImportedLoadAttachmentParams {
  id: string;
  type: LoadDocumentType;
}

/**
 * Import load attachment data
 */
export interface ImportedLoadAttachmentData {
  id: string;
  emailId: string;
  type: string;
  docType: LoadDocumentType;
  name?: string;
  key?: string;
  size?: number;
  visibility?: boolean;
}

/**
 * Imported load (forwarded email)
 */
export interface ImportedLoad {
  id: string;
  attachments: ImportedLoadAttachment[];
  content: string;
  created_at: string;
  sender_email: string;
  sender_name: string;
  tenant_id: string;
  updated_at?: string;
}

/**
 * Body params specific for filtering Loads
 */
export interface LoadSearchParams {
  from?: string; // generic start date for querying both pickup and delivery dates
  to?: string; // generic end date for querying both pickup and delivery dates
  pickupFrom?: string; // pickup specific start date
  pickupTo?: string; // pickup specific end date
  deliveryFrom?: string; // delivery specific start date
  deliveryTo?: string; // delivery specific end date
  status?: LoadStatus[];
  load_id?: string;
  includeArchived?: boolean;
  shipper?: string[];
  broker?: string[];
  driver?: string[];
  dispatcher?: (string | null)[];
  customerOrderNumber?: string;
  pickupLocation?: string[];
  deliveryLocation?: string[];
  pickupCity?: string;
  deliveryCity?: string;
  loadIds?: string[];
  carrier?: string[];
  truckId?: string[];
  trailerId?: string[];
  prevStatus?: LoadStatus[];
  role?: USER_ROLE;
  divisionId?: string[];
  tagIds?: string[];
  assignedStatus?: ASSIGNED_STATUS[];
  pickupCountry?: string;
  deliveryCountry?: string;
}

// TODO remove once v2 invoices are complete
export interface LoadInvoiceResponse {
  id?: string;
  load_id?: string;
  sent?: boolean;
  source: InvoiceSources;
  status: INVOICE_STATUS;
  qbo_invoice_id?: string;
  qbo_invoice_number?: string;
  invoice_number: string;
  tenant_id?: string;
  external_link: string;
  amount?: string;
  line_items?: InvoiceItem[];
  division_id?: string;
  created_at?: string;
}

export interface GetLoadsConfig {
  calendar?: boolean;
}

export interface LoadIntermediateStop {
  id: string;
  load_id: string;
  location_id: string;
  date: string;
  from_time?: string;
  to_time?: string;
  order: number;
  stop_type?: STOP_TYPE | null;
  location?: LoadLocation;
}

export interface LoadGrossPayEstimateData {
  unit?: DRIVER_DEFAULT_PAY_RATE_TYPE | string;
  rate?: number;
  quantity?: number;
  loaded_miles?: number;
  empty_miles?: number;
  gross_pay_estimate?: number;
  load_segment_revenue?: number;
  total_loaded_miles?: number;
  overriden_load_rate?: number;
  qbo_service_id?: string | null;
  invoice_service_id?: string | null;
}

export type AttachmentCopy = {
  id: string;
  type?: string;
  visibility?: boolean;
};

// V3
// TODO: Consider cleaning up code and interfaces after wiring with backend
export interface UpsertLoadDataV3 {
  id?: string;
  primary_client: LoadClientType;
  status: LoadStatus;
  tz_usage?: string;
  shipper_id?: string;
  broker_id?: string;
  rate?: number;
  load_rates?: LoadRate[];
  customer_order_number?: string;
  send_order_update?: boolean;
  configurations?: LoadNotificationSettingsRequest | null;
  dispatcher_id?: string;
  segments: TripData[];
  attachmentList?: AttachmentCopy[];
  documents?: string[];
  customer_contact_id?: string;
  edi_load_tender_id?: string;
  load_type?: LOAD_TYPE;
  division_id?: string;
  is_brokered_load: boolean;
  payable_amount?: number;
  load_commodities: LoadCommodities[];
  override_qbo_attributes?: boolean;
}

export interface UpsertLoadParamsV3 {
  id?: string;
  primary_client: LoadClientType;
  status: LoadStatus;
  tz_usage?: string;
  shipper_id?: string;
  broker_id?: string;
  rate?: number;
  load_rates?: LoadRate[];
  customer_order_number?: string;
  dispatcher_id?: string;
  segments: CreateSegment[];
  attachmentList?: AttachmentCopy[];
  send_order_update?: boolean;
  configurations?: LoadNotificationSettingsRequest | null;
  documents?: string[];
  customer_contact_id?: string;
  edi_load_tender_id?: string;
  load_type?: LOAD_TYPE;
  division_id?: string;
  is_brokered_load: boolean;
  payable_amount?: number;
  load_commodities: LoadCommodities[];
  override_qbo_attributes?: boolean;
}

export interface CreateSegment {
  id?: string;
  truck_id?: string | null;
  trailer_id?: string | null;
  carrier_id?: string | null;
  order: number;
  instructions?: string | null;
  pickup_number?: string | null;
  status?: string;
  odometer_start?: number | null;
  odometer_end?: number | null;
  stops: CreateStop[];
  drivers?: TripDriver[];
}
export interface CreateStop {
  location_id?: string | null;
  order: number;
  start_date: string;
  start_time?: string | null;
  end_date: string;
  end_time?: string | null;
  stop_type?: STOP_TYPE;
  stop_identifier?: string | null;
  notes?: string | null;
  edi_stop_identifier?: string | null;
  in_time?: string | null;
  out_time?: string | null;
}

export interface StopSignature {
  id: string;
  file: string;
  full_name: string;
  created_at: string;
  file_key: string;
  load_id: string;
  load_stop_id: string;
}

export interface StopData {
  id?: string | null;
  location_id?: string | null;
  order: number;
  start_date: string;
  start_time?: string | null;
  end_date: string;
  end_time?: string | null;
  stop_type?: STOP_TYPE;
  stop_identifier?: string | null;
  notes?: string | null;
  status?: StopStatus;
  contact?: {
    contact_name?: string;
    contact_number?: string;
    contact_number_ext?: string;
    contact_email?: string;
  };
  edi_stop_identifier?: string | null;
  in_time?: string | null;
  out_time?: string | null;
  is_in_time_overriden?: boolean;
  is_out_time_overriden?: boolean;
}

export interface LoadQboAttributes {
  division_qbo_credentials_id?: string | null;
}

export interface ImportLoadDataV3 extends UpsertLoadDataV3 {
  email_id?: string;
  edi_load_tender_id?: string;
  customer_order_request_id?: string;
  attachments?: ImportedLoadAttachmentData[];
}

export interface ImportLoadParamsV3 extends UpsertLoadParamsV3 {
  email_id?: string;
  edi_load_tender_id?: string;
  customer_order_request_id?: string;
  attachments?: ImportedLoadAttachmentParams[];
}

export interface SearchLoadParams {
  filters?: LoadSearchParams;
  params?: BaseQueryParams;
  config?: GetLoadsConfig;
}

export interface LoadRate {
  id?: string;
  load_id?: string | null;
  invoice_service_id?: string;
  qbo_service_id?: string;
  qbo_service_type?: string;
  service_name?: string;
  unit: InvoiceServiceUnit | string | null;
  rate: number | null;
  quantity: number;
  amount?: number;
  sort_order: number;
}

export interface InboxItemCount {
  edi_requests: number;
  customer_order_requests: number;
  emails: number;
}

export interface BulkUploadParams {
  searchFilters?: LoadSearchParams;
  updateActions: {
    status?: LoadStatus;
    archived?: boolean;
  };
}

export interface ButlerModel {
  id: string;
  name: string;
  is_default: boolean;
}

export const ORDERFUL_SHIPMENT_STATUS_CODES = [
  'XB',
  'AG',
  'X3',
  'AF',
  'X6',
  'X1',
  'CD',
  'ZZ',
  'SD'
] as const;

// Derive the TypeScript type from the array
export type ORDERFUL_SHIPMENT_STATUS_CODE = typeof ORDERFUL_SHIPMENT_STATUS_CODES[number];

export interface EdiStopMessageUpdate {
  status?: ORDERFUL_SHIPMENT_STATUS_CODE;
  reasonCode?: EdiDelayReasonCode;
  appointmentCode?: 'AA' | 'AB';
  isCheckInOrOutUpdate?: boolean;
}

/**
 * Returns a list of statuses to show in a column based on user role
 * @param role
 * @returns
 */
export const getDeliveredColumnStatusesByRole = (role: USER_ROLE | undefined): ORDER_STATUS[] => {
  switch (role) {
    case USER_ROLE.DRIVER:
      return [
        ORDER_STATUS.DELIVERED // delivered should be at top of the list as this determines the label of that column
      ];
    default:
      return [ORDER_STATUS.DELIVERED];
  }
};
