import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';

/**
 * Log a warning and error
 */
export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) =>
  (next) =>
  (action): void => {
    if (isRejectedWithValue(action)) {
      // TODO: dispatch error notification once Notification provider is moved to redux
      console.warn({ action });
    }

    return next(action);
  };
