import React, { createContext, FC, useEffect, ReactNode } from 'react';
import useNotifications from '../Notifications/Notifications.provider';
import api, { apiMultipart } from '../../utils/api';
import { AxiosError, AxiosResponse } from 'axios';
import AuthService from '../Auth/Auth.service';
import { logRocketTrack } from '../../utils/logRocketUtils';
import useAuth from '../Auth/Auth.provider';

/**
 * This provider will handle network or api errors
 */

export const ErrorManagerContext = createContext(null);

export const ErrorManagerProvider: FC<{ children: ReactNode }> = ({ children, ...props }) => {
  const {
    data: { userData }
  } = useAuth();
  const { addNotification } = useNotifications();

  useEffect(() => {
    const errorInterceptor = (error: AxiosError): Promise<AxiosError> => {
      if (!error.response) {
        logRocketTrack('Error Manager', {
          action: error.message,
          severity: 'low',
          tenant_id: userData?.tenant?.id,
          company_name: userData?.tenant?.company_name,
          email: userData?.email_address,
          user_first_name: userData?.first_name,
          user_last_name: userData?.last_name
        });
        addNotification({
          message: 'Network error. Please connect to the internet and try again.',
          position: 'bottomCenter',
          variant: 'error',
          timeToShow: 7000
        });
      }

      if (
        error.response?.status === 401 &&
        !window.location.pathname.includes('/create-password') &&
        !window.location.pathname.includes('/forgot-password')
      ) {
        let redirect = false;

        if (window.location.pathname === '/' && error.config.url !== '/me') {
          redirect = true;
        }
        if (
          window.location.pathname !== '/' &&
          !window.location.pathname.includes('signup') &&
          !window.location.pathname.includes('expired-invite') &&
          !window.location.pathname.includes('already-signed-up') &&
          !window.location.pathname.includes('signin') &&
          !window.location.pathname.includes('/p/')
        ) {
          redirect = true;
        }

        if (redirect) AuthService.signOut().finally(() => (window.location.href = '/'));
      }
      return Promise.reject(error);
    };

    const interceptor = api.interceptors.response.use(
      (success: AxiosResponse) => success,
      errorInterceptor
    );
    const multipartInterceptor = apiMultipart.interceptors.response.use(
      (success: AxiosResponse) => success,
      errorInterceptor
    );

    return (): void => {
      api.interceptors.response.eject(interceptor);
      apiMultipart.interceptors.response.eject(multipartInterceptor);
    };
  }, [addNotification]);

  return (
    <ErrorManagerContext.Provider value={null} {...props}>
      {children}
    </ErrorManagerContext.Provider>
  );
};

export default ErrorManagerProvider;
