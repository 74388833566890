import LogRocket from 'logrocket';
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer';
import { UserResponse } from '../providers/User/User.model';

export interface BasicTenant {
  id: string;
  company_name: string;
}

interface IUserTraits {
  [propName: string]: string | number | boolean;
}

export const addLogRocketIdentity = (userResponse?: UserResponse, tenant?: BasicTenant): void => {
  // If user Response exists identify user and return
  if (userResponse) {
    identifyLogRocketForUser(userResponse);
    return;
  }

  // If tenant exists identify anonymous
  if (tenant) {
    identifyLogRocketForAnonymous(tenant);
  }
};

const identifyLogRocketForUser = (userResponse: UserResponse): void => {
  // Basic User Info
  const userInfo: any = {
    displayName: `${userResponse.first_name} ${userResponse.last_name}`,
    email: userResponse.email_address
  };

  // If User response has tenant add that user tenantId and companyName
  if (userResponse?.tenant) {
    userInfo.tenantId = userResponse.tenant.id;
    userInfo.companyName = userResponse.tenant.company_name;
  }

  LogRocket.identify(userResponse.id, userInfo);
};

const identifyLogRocketForAnonymous = (tenant: BasicTenant): void => {
  // Basic Tenant Info
  const tenantInfo: IUserTraits = {
    tenantId: tenant.id,
    companyName: tenant.company_name
  };

  LogRocket.identify(tenantInfo);
};

// pulling out from LogrocketFuzzySanitizer to cast IRequest below
interface INetworkRequestResponse {
  body: any;
  method: string;
  headers: any;
}

/** Initialized LogRocket util with request body sanitizer to prevent
 * logging sensitive data */
export const initializeLogRocket = (apiKey: string): void => {
  // fields we don't want logged in LogRocket
  const privateFieldNames = ['password'];

  // using LogRocketFuzzySanitizer plugin to sanitize network requests but still logging the body
  const fuzzySanitizer = LogrocketFuzzySanitizer.setup(privateFieldNames);
  const fsRequestSanitizer = fuzzySanitizer.requestSanitizer;

  LogRocket.init(apiKey, {
    network: {
      isEnabled: true,
      requestSanitizer: (request) => {
        return fsRequestSanitizer(request as INetworkRequestResponse);
      }
    }
  });
};

type TrackSeverity = 'low' | 'medium' | 'high' | 'critical';
interface LogRocketTrackEventProps {
  action: string;
  severity: TrackSeverity;
  tenant_id?: string;
  company_name?: string;
  email?: string;
  user_first_name?: string;
  user_last_name?: string;
}

export const logRocketTrack = (eventName: string, eventProps?: LogRocketTrackEventProps): void => {
  const { user_first_name, user_last_name, ...restProps } = eventProps || {};
  const displayName = `${user_first_name || ''} ${user_last_name || ''}`.trim();

  LogRocket.track(eventName, { ...restProps, displayName });
};
