import { TripSearchResponse } from '../../api/trips/trips.model';

export enum CalendarViewType {
  Week = 7,
  TwoDays = 2
}

export type CalendarDisplayContentType = 'driver' | 'truck';

export interface CalendarItem {
  id: string;
  name: string;
  shortName: string;
  loadsCount: number;
  loads: TripSearchResponse[];
  isItemDeleted?: boolean;
  type: CalendarDisplayContentType;
}

export const DETAILED_PERIOD_HOURS = [
  { key: 12, label: '12AM' },
  { key: 2, label: '2AM' },
  { key: 4, label: '4AM' },
  { key: 6, label: '6AM' },
  { key: 8, label: '8AM' },
  { key: 10, label: '10AM' },
  { key: 12, label: '12PM' },
  { key: 2, label: '2PM' },
  { key: 4, label: '4PM' },
  { key: 6, label: '6PM' },
  { key: 8, label: '8PM' },
  { key: 10, label: '10PM' }
];
