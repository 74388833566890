import api, { twUrl } from '../../utils/api';
import { InvitedUserResponse, UserResponse } from '../User/User.model';
import {
  SignUpParams,
  SignInParams,
  SignUpData,
  SignInData,
  SignUpResponse,
  SignInResponse,
  SurveyData,
  SurveyParams,
  SurveyResponse,
  ForgotPasswordParams,
  CreatePasswordData,
  CreatePasswordParams
} from './Auth.model';

class AuthService {
  /**
   * Signs user up
   * @param signUpData
   */
  static signUp(signUpData: SignUpData, inviteToken?: string): Promise<SignUpResponse> {
    return new Promise((resolve, reject) => {
      const params: SignUpParams = {
        first_name: signUpData.firstName.trim(),
        last_name: signUpData.lastName.trim(),
        email_address: signUpData.email.trim(),
        password: signUpData.password,
        company_name: signUpData.companyName.trim(),
        usdot_number: signUpData.usdot
      };

      signUpData.phone ? (params.phone = signUpData.phone.trim()) : null;

      const apiResponse = api.post(twUrl.signUp(inviteToken), params);

      apiResponse
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  }

  /**
   * Saves user survey information
   * @param surveyData
   * @param companyId
   */
  static sendSurvey(surveyData: SurveyData): Promise<SurveyResponse> {
    return new Promise((resolve, reject) => {
      const {
        role,
        otherRole,
        countries,
        numberOfDrivers,
        hasLeasedOperators,
        hasMultipleDispatchers,
        doesDispatchForOtherCarriers
      } = surveyData;

      const params: SurveyParams = {
        user_role: '',
        drivers_to_manage: 1,
        countries_it_operates_in: ['United States'],
        has_leased_operators: hasLeasedOperators,
        has_multiple_dispatchers: hasMultipleDispatchers,
        dispatch_for_other_carriers: doesDispatchForOtherCarriers
      };

      role === 'Other' ? (params.user_role = otherRole) : (params.user_role = role);
      !!numberOfDrivers ? (params.drivers_to_manage = numberOfDrivers) : null;
      !!countries.length ? (params.countries_it_operates_in = countries) : null;

      const apiResponse = api.post(twUrl.sendSurvey, params);

      apiResponse
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  }

  /**
   * Signs user in
   * @param signInData
   */
  static signIn(signInData: SignInData): Promise<SignInResponse> {
    return new Promise((resolve, reject) => {
      const params: SignInParams = {
        email_address: signInData.email.trim(),
        password: signInData.password
      };

      const apiResponse = api.post(twUrl.signIn, params);

      apiResponse
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  }

  /**
   * Sign user out
   */
  static signOut(): Promise<number> {
    return new Promise((resolve, reject) => {
      const apiResponse = api.get(twUrl.signOut);

      apiResponse
        .then((response) => {
          resolve(response.status);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  }

  /**
   * Gets current user data
   */
  static getCurrentUser(): Promise<UserResponse> {
    return new Promise((resolve, reject) => {
      const apiResponse = api.get(twUrl.currentUser);

      apiResponse
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  /**
   * Gets invited user data
   */
  static getInvitedUser(inviteToken: string | undefined): Promise<InvitedUserResponse> {
    return new Promise((resolve, reject) => {
      const apiResponse = api.get(twUrl.inviteSignUp(inviteToken));

      apiResponse
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          reject(error.response.status);
        });
    });
  }

  /**
   * Sends reset password email to user
   * @param email
   */
  static forgotPassword(email: string): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const params: ForgotPasswordParams = {
        email_address: email.trim()
      };

      const apiResponse = api.post(twUrl.forgotPass, params);

      apiResponse
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  }

  /**
   * Creates new password for the current user
   * @param password
   */
  static createPassword(createPasswordData: CreatePasswordData): Promise<unknown> {
    return new Promise((resolve, reject) => {
      const params: CreatePasswordParams = {
        password: createPasswordData.password,
        password_confirmation: createPasswordData.confirmPassword,
        token: createPasswordData.token
      };

      const apiResponse = api.post(twUrl.createPass, params);

      apiResponse
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  }
}

export default AuthService;
