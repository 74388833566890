import { BaseQueryParams } from '../../Global.model';

export enum InvoiceServiceUnit {
  FLAT = 'FLAT',
  PER_MILE = 'PER_MILE',
  PER_HOUR = 'PER_HOUR',
  PER_DAY = 'PER_DAY',
  PER_STOP = 'PER_STOP',
  PER_UNIT = 'PER_UNIT'
}

export enum InvoiceSources {
  TB = 'Truckbase',
  QBO = 'Quickbooks'
}

export enum InvoiceServiceStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED'
}

export interface InvoiceService {
  id: string;
  name: string;
  unit: InvoiceServiceUnit;
  default_rate: number;
  status: InvoiceServiceStatus;
  type?: string;
  isDefault?: boolean;
  external_service_id?: string;
}

export interface CreateInvoiceServiceParams {
  name: string;
  unit: InvoiceServiceUnit;
  default_rate?: number | undefined;
  external_service_id?: string;
}

export interface SearchInvoiceServicesParams extends BaseQueryParams {
  source?: InvoiceSources;
}

export interface UpdateInvoiceServiceParams {
  id: string;
  name: string;
  unit: InvoiceServiceUnit;
  default_rate?: number | undefined;
  external_service_id?: string;
  status?: InvoiceServiceStatus;
}

export interface UpdateInvoiceServiceStatusParams {
  id: string;
  status: InvoiceServiceStatus;
}

export interface InvoiceServiceSearchFilters {
  createdBeforeDate?: Date;
  from?: string;
  to?: string;
  invoiceServicesIds?: string[];
  includeDisabled?: boolean;
  status?: InvoiceServiceStatus[];
  divisionId: string;
}

export const LINEHAUL_FLAT_RATE_NAME = 'Linehaul flat rate';

const invoiceServiceUnitKeys = [...Object.keys(InvoiceServiceUnit)];

const uniqueInvoiceServiceUnitKeys = new Set(invoiceServiceUnitKeys);

export const getInvoiceServiceUnitLabel = (unit: string): string => {
  if (uniqueInvoiceServiceUnitKeys.has(unit)) {
    return (unit.charAt(0).toUpperCase() + unit.slice(1).toLowerCase()).replaceAll('_', ' ');
  }

  return unit;
};
