import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import AuthService from '../../providers/Auth/Auth.service';
import { apiLongTimeout } from '../../utils/api'; // Ensure this is the correct path
import type { AxiosRequestConfig } from 'axios';

const axiosBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  let config: AxiosRequestConfig = {};

  if (typeof args === 'string') {
    config.url = args;
  } else {
    const { url, method, body, params, headers } = args;
    config = {
      url,
      method,
      data: body,
      params,
      headers: headers as AxiosRequestConfig['headers'] // Type cast headers
    };
  }

  try {
    const result = await apiLongTimeout(config);
    return { data: result.data };
  } catch (axiosError: any) {
    return {
      error: {
        status: axiosError.response?.status,
        data: axiosError.response?.data
      } as FetchBaseQueryError
    };
  }
};

const baseQuery = fetchBaseQuery({ baseUrl: '/api/' });

// Custom base query with redirection on API error
export const baseQueryWithRedirection: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const shouldUseAxios = (typeof args === 'string' ? args : args.url) === 'v3/invoices';
  const result = shouldUseAxios
    ? await axiosBaseQuery(args, api, extraOptions)
    : await baseQuery(args, api, extraOptions);

  if (
    result.error &&
    result.error.status === 401 &&
    !window.location.pathname.includes('/create-password') &&
    !window.location.pathname.includes('/forgot-password')
  ) {
    let redirect = false;

    if (window.location.pathname === '/') {
      redirect = true;
    }
    if (
      window.location.pathname !== '/' &&
      !window.location.pathname.includes('signup') &&
      !window.location.pathname.includes('expired-invite') &&
      !window.location.pathname.includes('already-signed-up') &&
      !window.location.pathname.includes('signin') &&
      !window.location.pathname.includes('/p/')
    ) {
      redirect = true;
    }

    if (redirect) AuthService.signOut().finally(() => (window.location.href = '/'));
  }
  return result;
};
