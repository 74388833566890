import React, { useEffect } from 'react';
import useAuth from '../src/providers/Auth/Auth.provider';
import { TwUpdateSnackbar } from './components/TwSnackbar/TwSnackbar';
import FullPageSpinner from './components/TwFullPageProgress/TwFullPageProgress';
import * as Sentry from '@sentry/react';
import { hideWidget } from './utils/widgetUtils';

if ('REACT_APP_DSN' in process.env) {
  Sentry.init({
    dsn: process.env.REACT_APP_DSN,
    tracesSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.REACT_APP_VERSION
  });
}

const PublicApp = React.lazy(() => import('./PublicApp'));
const App = React.lazy(() => import('./App'));

function TruckbaseApp(): JSX.Element {
  const {
    data: { userAuthorized: isAuth, userData }
  } = useAuth();

  useEffect(() => {
    hideWidget();
  }, []);

  useEffect(() => {
    if (!isAuth || userData?.role === 'CUSTOMER_CONTACT') hideWidget();
  }, [isAuth, userData?.role]);

  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      <TwUpdateSnackbar />
      {isAuth ? <App /> : <PublicApp />}
    </React.Suspense>
  );
}

export default TruckbaseApp;
