import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface IUserState {
  isLtlEnabled: boolean;
}

const initialState: IUserState = {
  isLtlEnabled: false
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserLtlEnabled: (state, action: PayloadAction<boolean>) => {
      state.isLtlEnabled = action.payload;
    }
  }
});

export const { setUserLtlEnabled } = userSlice.actions;
export default userSlice.reducer;
