import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FilterParams } from '../../api/savedViews/savedViews.model';

interface ActiveTripsState {
  filters?: FilterParams;
}

// This can be reused or take as guide for other pages aside from active loads
export const activeTripsSlice = createSlice({
  name: 'activeTrips',
  initialState: {
    filters: {
      includeArchived: false
    }
  } as ActiveTripsState,
  reducers: {
    setFilters: (state, action: PayloadAction<FilterParams | undefined>) => {
      state.filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = {
        includeArchived: false
      };
    }
  }
});

export const { resetFilters, setFilters } = activeTripsSlice.actions;

export default activeTripsSlice.reducer;
