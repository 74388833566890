import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface EditStopDateTimeConfig {
  prevStopOutTime?: Date;
  prevDateTime?: Date;
  dateTime: Date;
  nextDateTime?: Date;
  nextStopInTime?: Date;
  title?: string;
  dateInputLabel?: string;
  segment_id?: string;
  load_stop_id?: string;
  type: 'in' | 'out';
}

interface PublicLoadState {
  loadId?: string;
  editDateTimeConfig: EditStopDateTimeConfig;
}

const PUBLIC_LOAD_INITIAL_STATE: PublicLoadState = {
  loadId: '',
  editDateTimeConfig: {
    dateTime: new Date(),
    title: '',
    dateInputLabel: '',
    segment_id: '',
    load_stop_id: '',
    type: 'in'
  }
};

export const publicLoadSlice = createSlice({
  name: 'publicLoad',
  initialState: PUBLIC_LOAD_INITIAL_STATE,
  reducers: {
    clear: (state) => {
      state = PUBLIC_LOAD_INITIAL_STATE;
    },
    setLoadId: (state, action: PayloadAction<string>) => {
      state.loadId = action.payload;
    },
    setEditDateTimeConfig: (state, action: PayloadAction<EditStopDateTimeConfig>) => {
      state.editDateTimeConfig = action.payload;
    },
    resetEditDateTimeConfig: (state) => {
      state.editDateTimeConfig = PUBLIC_LOAD_INITIAL_STATE.editDateTimeConfig;
    }
  }
});

export const { clear, setLoadId, setEditDateTimeConfig, resetEditDateTimeConfig } =
  publicLoadSlice.actions;

export default publicLoadSlice.reducer;
