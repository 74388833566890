import React, { useState } from 'react';
import useId from '../../hooks/useId';
import useNotifications from '../../providers/Notifications/Notifications.provider';

import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import './TwSnackbar.scss';
import useServiceWorker from '../../hooks/useServiceWorker';

const getPosition = (position: string): SnackbarOrigin => {
  switch (position) {
    case 'topCenter':
      return { vertical: 'top', horizontal: 'center' };
    case 'topRight':
      return { vertical: 'top', horizontal: 'right' };
    case 'bottomCenter':
      return { vertical: 'bottom', horizontal: 'center' };
    case 'bottomLeft':
      return { vertical: 'bottom', horizontal: 'left' };
    case 'topLeft':
      return { vertical: 'top', horizontal: 'left' };
    case 'bottomRight':
    default:
      return { vertical: 'bottom', horizontal: 'right' };
  }
};

export type positionType =
  | 'topCenter'
  | 'topRight'
  | 'bottomCenter'
  | 'bottomLeft'
  | 'topLeft'
  | 'bottomRight';

const getIcon = (variant: string): any => {
  switch (variant) {
    case 'success':
      return <CheckCircleRoundedIcon className="snackbar__icon snackbar__success" />;
    case 'error':
      return <InfoOutlinedIcon className="snackbar__icon snackbar__error" />;
    case 'warning':
      return <WarningOutlinedIcon className="snackbar__icon snackbar__warning" />;
    case 'loading':
      return <CircularProgress size={16} className="snackbar__loading" />;
  }
};

export interface TwSnackbarOptions {
  message: string;
  variant: string;
  position?: positionType;
  timeToShow?: number | null;
  actionOptions?: TwSnackbarActions;
  onClosed?: () => void;
}

export interface TwSnackbarActions {
  action: string;
  onActionClick: () => void;
}

export interface TwSnackbarProps {
  options: TwSnackbarOptions;
  open?: boolean;
}

/**
 * @description this component renders a Snackbar
 * @param {string} message message to show
 * @param {string} variant 'success'|'error'|'loading' - icon to show in snackbar
 * @param {string} position 'topCenter'|'topRight'|'bottomCenter'|'bottomLeft'|'topLeft'|'bottomRight' - to show snackbar
 * @param {actionOptions} actionOptions object with two parameter action{string} and onActionClick callback of button
 * @param {number} timeToShow [3000 default] display time of the message until closes automatically
 * @param {function} onClosed fired when the component is  closed.
 */
const TwSnackbar: React.FC<TwSnackbarProps> = ({ options, open = true }) => {
  const {
    message,
    variant,
    position = 'bottomRight',
    timeToShow = 3000,
    actionOptions,
    onClosed
  } = options;

  const action = actionOptions?.action;
  const onActionClick = actionOptions?.onActionClick;

  const { setIsShowing } = useNotifications();

  const key = useId();

  const [isOpen, setIsOpen] = useState(open);

  const handleOnClose = (): void => {
    onClosed && onClosed();
    setIsOpen(false);
    setIsShowing(false);
  };

  const handleCLick = (): void => {
    handleOnClose();
    onActionClick && onActionClick();
  };

  const Body = (
    <div className="snackbar">
      {getIcon(variant)}
      <span className="snackbar__text">{message}</span>
    </div>
  );

  const Action = (
    <>
      <Button variant="text" onClick={handleCLick} className="snackbar__action">
        {action}
      </Button>
    </>
  );

  return (
    <Snackbar
      anchorOrigin={getPosition(position)}
      open={isOpen}
      message={Body}
      onClose={handleOnClose}
      autoHideDuration={timeToShow}
      action={action && onActionClick ? Action : null}
      key={key}
      sx={{
        // Added this because of Support Widget
        zIndex: 999999
      }}
      data-testid="tw-notification"
    />
  );
};

const TwUpdateSnackbar: React.FC = () => {
  const { showReload, reloadPage } = useServiceWorker();

  return (
    <Snackbar
      anchorOrigin={getPosition('topRight')}
      open={showReload}
      message={
        <div className="snackbar">
          <CheckCircleRoundedIcon className="snackbar__icon snackbar__success" />
          <span className="snackbar__text">
            Truckbase has been updated. Refresh to make it yours
          </span>
        </div>
      }
      autoHideDuration={null}
      action={
        <Button variant="text" onClick={reloadPage} className="snackbar__action">
          REFRESH
        </Button>
      }
      sx={{
        // Added this because of Support Widget
        zIndex: 999999
      }}
    />
  );
};

export default TwSnackbar;
export { TwUpdateSnackbar };
