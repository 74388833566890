/* eslint-disable @typescript-eslint/ban-types */
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FullPageSpinner from '../../components/TwFullPageProgress/TwFullPageProgress';
import {
  DivisionQboCredential,
  FactoringCompany,
  InvoiceDescriptionSettings,
  TenantAccountingSettings,
  TenantSetting,
  UserDisplaySettings,
  UserResponse
} from '../User/User.model';
import AuthService from './Auth.service';
import surveyOptionsList, { SurveyOptions } from './surveyOptions';

export interface AuthProviderData {
  userAuthorized: boolean;
  userData?: UserResponse;
  surveyOptions: SurveyOptions;
  dataLoading: boolean;
}

export interface AuthContextProps {
  data: AuthProviderData;
  updateDataLoading: Function;
  updateAuthData: Function;
  updateUserData: Function;
  signOut: () => void;
  updateTenantSetting: (tenantSetting: TenantSetting) => void;
  getTenantSetting: (key: string, defaultValue?: boolean) => boolean;
  updateTenantAccountingSetting: (tenantAccSetting: TenantAccountingSettings) => void;
  updateTenantAccountingInvoiceSetting: (tenantInvoiceSetting: InvoiceDescriptionSettings) => void;
  getTenantAccountingSetting: (
    key: keyof TenantAccountingSettings
  ) => boolean | string | undefined | DivisionQboCredential | FactoringCompany;
  getTenantAccountingInvoiceSetting: (
    key: keyof InvoiceDescriptionSettings
  ) => boolean | string | undefined;
  updateUserDisplaySettings: (userDisplaySettings: UserDisplaySettings) => void;
  refreshCurrentUser: () => void;
}

export const defaultProviderData = {
  userAuthorized: false,
  userData: undefined,
  surveyData: {
    role: '',
    otherRole: '',
    reason: '',
    otherReason: '',
    ownedTrucks: '',
    loadsPerWeek: '',
    freightTypes: ['']
  },
  surveyOptions: surveyOptionsList,
  dataLoading: true
};

export const AuthContext = createContext<AuthContextProps>({
  data: defaultProviderData,
  updateDataLoading: Function,
  updateAuthData: Function,
  updateUserData: Function,
  signOut: () => null,
  updateTenantSetting: () => null,
  getTenantSetting: () => false,
  updateTenantAccountingSetting: () => null,
  getTenantAccountingSetting: () => false,
  updateTenantAccountingInvoiceSetting: () => null,
  getTenantAccountingInvoiceSetting: () => false,
  updateUserDisplaySettings: () => null,
  refreshCurrentUser: () => null
});

AuthContext.displayName = 'AuthProvider';

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  const [providerValue, setProviderValue] = useState<AuthProviderData>(defaultProviderData);

  /**
   * Updates user data information with new state
   * @param userData
   */
  const updateUserData = (userData: UserResponse): void => {
    setProviderValue((prevValues: AuthProviderData) => {
      return { ...prevValues, userData };
    });
  };

  /**
   * Updates auth information with new state
   * @param userAuthorized
   */
  const updateAuthData = (userAuthorized: boolean): void => {
    setProviderValue((prevValues) => {
      return { ...prevValues, userAuthorized };
    });
  };

  /**
   * Updates data loading status
   * @param dataLoading Shows true when data is being retrieved or updated
   * and false if data is ready.
   */
  const updateDataLoading = (dataLoading: boolean): void => {
    setProviderValue((prevValues) => {
      return { ...prevValues, dataLoading };
    });
  };

  const signOut = async (): Promise<void> => {
    const response = await AuthService.signOut();

    if (response !== 200) return;

    updateAuthData(false);
    navigate('/signin', { replace: true });
  };

  /**
   * Update tenantSetting if exists or add if not
   * @param tenantSetting
   */
  const updateTenantSetting = (tenantSetting: TenantSetting): void => {
    // because tenantSetting is a object is necessary use object.keys
    const [key] = Object.keys(tenantSetting);

    const oldTenantSetting = providerValue.userData?.tenantSettings;
    let newData: AuthProviderData;

    if (oldTenantSetting !== undefined && oldTenantSetting.hasOwnProperty(key)) {
      const tenantSettings = { ...oldTenantSetting, ...tenantSetting };

      newData = {
        ...providerValue,
        userData: { ...providerValue.userData, tenantSettings }
      } as AuthProviderData;
    } else {
      // this is in the case the property doesn't exist
      newData = {
        ...providerValue,
        userData: {
          ...providerValue.userData,
          tenantSettings: {
            ...providerValue.userData?.tenantSettings,
            ...tenantSetting,
            allow_multi_stops_per_load: true,
            'advanced-driver-fields': true,
            'shipper-and-broker-fields': false
          }
        } as unknown as UserResponse
      } as AuthProviderData;
    }

    setProviderValue(newData);
  };

  const getTenantSetting = (key: string, defaultValue = false): boolean =>
    (providerValue.userData?.tenantSettings && providerValue.userData.tenantSettings[key]) ||
    defaultValue;

  /**
   * Update tenantAccountingSetting if exists or add if not
   * @param tenantAccSetting
   */
  const updateTenantAccountingSetting = (tenantAccSetting: TenantAccountingSettings): void => {
    const [key] = Object.keys(tenantAccSetting);

    const oldTenantAccSetting = providerValue.userData?.tenantAccountingSettings;
    let newData: AuthProviderData;

    if (oldTenantAccSetting !== undefined && oldTenantAccSetting.hasOwnProperty(key)) {
      const tenantAccSettings = { ...oldTenantAccSetting, ...tenantAccSetting };

      newData = {
        ...providerValue,
        userData: { ...providerValue.userData, tenantAccSettings }
      } as AuthProviderData;
    } else {
      // this is in the case the property doesn't exist
      newData = {
        ...providerValue,
        userData: {
          ...providerValue.userData,
          tenantAccountingSettings: {
            ...providerValue.userData?.tenantAccountingSettings,
            ...tenantAccSetting
          }
        } as unknown as UserResponse
      } as AuthProviderData;
    }

    setProviderValue(newData);
  };

  /**
   * Update tenantAccountingInvoiceSetting if exists or add if not
   * @param tenantInvoiceSetting
   */
  const updateTenantAccountingInvoiceSetting = (
    tenantInvoiceSetting: InvoiceDescriptionSettings
  ): void => {
    const [key] = Object.keys(tenantInvoiceSetting);

    const oldTenantInvoiceSetting =
      providerValue.userData?.tenantAccountingSettings?.invoice_description;
    let newData: AuthProviderData;

    if (oldTenantInvoiceSetting !== undefined && oldTenantInvoiceSetting.hasOwnProperty(key)) {
      const tenantInvSettings = { ...oldTenantInvoiceSetting, ...tenantInvoiceSetting };

      newData = {
        ...providerValue,
        userData: {
          ...providerValue.userData,
          tenantAccountingSettings: {
            ...providerValue.userData?.tenantAccountingSettings,
            invoice_description: {
              ...providerValue.userData?.tenantAccountingSettings?.invoice_description,
              ...tenantInvSettings
            }
          }
        } as unknown as UserResponse
      } as AuthProviderData;
    } else {
      // this is in the case the property doesn't exist
      newData = {
        ...providerValue,
        userData: {
          ...providerValue.userData,
          tenantAccountingSettings: {
            ...providerValue.userData?.tenantAccountingSettings,
            invoice_description: {
              ...providerValue.userData?.tenantAccountingSettings?.invoice_description,
              ...tenantInvoiceSetting
            }
          }
        } as unknown as UserResponse
      } as AuthProviderData;
    }

    setProviderValue(newData);
  };

  const getTenantAccountingSetting = (
    key: keyof TenantAccountingSettings
  ): boolean | string | undefined | DivisionQboCredential | FactoringCompany => {
    if (key !== 'invoice_description') {
      return (
        providerValue.userData?.tenantAccountingSettings &&
        providerValue.userData.tenantAccountingSettings[key]
      );
    }
  };

  const getTenantAccountingInvoiceSetting = (
    key: keyof InvoiceDescriptionSettings
  ): boolean | string | undefined => {
    return (
      providerValue.userData?.tenantAccountingSettings?.invoice_description &&
      providerValue.userData.tenantAccountingSettings.invoice_description[key]
    );
  };

  const updateUserDisplaySettings = (userDisplaySettings: UserDisplaySettings): void => {
    const newData = {
      ...providerValue,
      userData: {
        ...providerValue.userData,
        userDisplaySettings: userDisplaySettings
      }
    } as AuthProviderData;

    setProviderValue(newData);
  };

  // this is temporal until we migrate auth to RTK
  const refreshCurrentUser = async (): Promise<void> => {
    AuthService.getCurrentUser().then((user) => {
      updateUserData(user);
    });
  };

  useEffect(() => {
    AuthService.getCurrentUser()
      .then((user) => {
        updateAuthData(true);
        updateUserData(user);
      })
      .catch(() => {
        updateAuthData(false);
      })
      .finally(() => {
        updateDataLoading(false);
      });
  }, []);

  const providerData = {
    data: providerValue,
    updateAuthData,
    updateDataLoading,
    updateUserData,
    signOut,
    updateTenantSetting,
    getTenantSetting,
    updateTenantAccountingSetting,
    getTenantAccountingSetting,
    updateTenantAccountingInvoiceSetting,
    getTenantAccountingInvoiceSetting,
    updateUserDisplaySettings,
    refreshCurrentUser
  };

  if (providerData.data.dataLoading) {
    return <FullPageSpinner />;
  }

  return (
    <AuthContext.Provider value={providerData}>
      {!providerData.data.dataLoading && children}
    </AuthContext.Provider>
  );
};

export default function useAuth(): AuthContextProps {
  return useContext(AuthContext);
}
